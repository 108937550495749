import { IActionContext } from '@msdyn365-commerce/core';
import { ProductRefinerValue, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import getOrganizationCustomer, { GetOrganizationCustomerInput } from '../actions/get-organization-customer';

function isGodmodeEnabled(context: IActionContext): boolean {
    const godmode = context.requestContext.url.requestUrl.searchParams.get('godmode')?.toLowerCase();

    return godmode === '1' || godmode === 'true';
}

export function addOrUpdateZeroPriceRefiner(context: IActionContext, refiners?: ProductRefinerValue[]): ProductRefinerValue[] {
    const enableZeroPriceFilterConfig = context.requestContext.app.config?.enableZeroPriceFilter as boolean;

    // Check criteria for allowing user to see zero price products. Example:
    // - Has ?godmode=true in url
    const allowZeroPriceProducts = isGodmodeEnabled(context) || enableZeroPriceFilterConfig;

    if (!allowZeroPriceProducts) {
        const result = refiners ? [...refiners] : [];

        // Make sure we don't show zero price products
        const priceRefiner = result.find(x => x.RefinerRecordId === 0);

        if (priceRefiner) {
            // There is a price refiner, make sure it has a min value of 0.01 or more
            if (!priceRefiner.LeftValueBoundString || Number(priceRefiner.LeftValueBoundString) < 0.01) {
                priceRefiner.LeftValueBoundString = '0.01';
            }
        } else {
            // No price refiner, add one
            result.push({
                '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.ProductRefinerValue',
                Count: 0,
                DataTypeValue: 1,
                LeftValueBoundString: '0.01',
                ExtensionProperties: [],
                RefinerRecordId: 0,
                RefinerSourceValue: 3,
                RightValueBoundString: '1000000000',
                RowNumber: 0,
                UnitText: context.requestContext.channel?.Currency
            } as ProductRefinerValue);
        }

        return result;
    }

    return refiners || [];
}

export async function getAttributeRefiners(context: IActionContext, attributeRecordId: number, friendlyName?: string): Promise<ProductRefinerValue[]> {
    const result: ProductRefinerValue[] = [];
    const refiner: ProductRefinerValue = {
        DataTypeValue: 5,
        LeftValueBoundString: '0',
        RefinerRecordId: attributeRecordId,
        RefinerSourceValue: 1,
        RightValueBoundString: '0',
        UnitText: ''
    };

    result.push(refiner);

    try {
        let attributeValue = 0;

        if (context.requestContext.user.isAuthenticated) {
            // Get Customer attributes
            const { apiSettings, user: { customerAccountNumber } } = context.requestContext;
            const customer = await getOrganizationCustomer(new GetOrganizationCustomerInput(apiSettings, customerAccountNumber!), context);
            const attribute = Number(customer.Attributes?.find(x => x.RecordId === attributeRecordId)?.AttributeValue?.StringValue);

            if (isNaN(attribute)) {
                // Throw NaN error
                if (friendlyName) {
                    console.warn(`${friendlyName} attribute value is missing or not a number.`);
                } else {
                    console.warn(`Attribute with record id ${attributeRecordId} is missing a value or not a number.`);
                }
            } else {
                attributeValue = attribute;
            }
        } else {
            // If user is not logged in show all products
            attributeValue = 1;
        }

        if (attributeValue !== 0) {
            result.push({
                ...refiner,
                LeftValueBoundString: attributeValue.toString(),
                RightValueBoundString: attributeValue.toString()
            });
        }
    } catch (error) {
        if (friendlyName) {
            console.warn(`Failed to get ${friendlyName} customer attribute or parse value of attribute. No filter applied to search.`, error);
        } else {
            console.warn(`Failed to get customer attribute (record id: ${attributeRecordId}) or parse value of attribute. No filter applied to search.`, error);
        }
    }

    return result;
}

export async function addAttributeRefiner(context: IActionContext, searchCriteriaInput: ProductSearchCriteria, attributeRecordId: number): Promise<void> {
    if (searchCriteriaInput.Refinement!.some(x => x.RefinerRecordId === attributeRecordId)) {
        // Remove existing, shouldn't be here
        searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.filter(x => x.RefinerRecordId !== attributeRecordId);
    }

    const refiners = await getAttributeRefiners(context, attributeRecordId);

    searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.concat(refiners);
}

export async function addAllAttributeRefiners(context: IActionContext, searchCriteriaInput: ProductSearchCriteria): Promise<void> {
    const godmode = isGodmodeEnabled(context);

    if (godmode) {
        return;
    }

    const attributeRecordIds: string[] = [
        context.requestContext.app.config?.customerSpecificRefinerRecordId,
        context.requestContext.app.config?.tobaccoRefinerRecordId
    ];

    for (const attributeRecordId of attributeRecordIds) {
        const id = Number(attributeRecordId);

        // Make sure it's a number and above 0
        if (!isNaN(id) && id > 0) {
            await addAttributeRefiner(context, searchCriteriaInput, id);
        }
    }
}

export async function getAllAttributeRefiners(context: IActionContext) {
    const godmode = isGodmodeEnabled(context);

    if (godmode) {
        return [];
    }

    const attributeRecordIds: string[] = [
        context.requestContext.app.config?.customerSpecificRefinerRecordId,
        context.requestContext.app.config?.tobaccoRefinerRecordId
    ];

    const result: ProductRefinerValue[] = [];

    for (const attributeRecordId of attributeRecordIds) {
        const id = Number(attributeRecordId);

        // Make sure it's a number and above 0
        if (!isNaN(id) && id > 0) {
            const refiners = await getAttributeRefiners(context, id);
            result.push(...refiners);
        }
    }

    return result;
}